<template>
  <div class="sctp-bg-white">
    <div class="link-navigation sctp-container">
      <!-- <router-link :to="'/goods-info/goods-info-hall'"> -->
      <a>
        <div class="link-navigation-item first">
          <img src="/images/index/category.png" onerror="this.parentElement.removeChild(this)">
          {{ $t('category.all') }}
        </div>
      </a>
      <!-- </router-link> -->
      <router-link to="/#">
        <div class="link-navigation-item">{{ $t('category.home') }}</div>
      </router-link>
      <router-link to="/center/requirement">
        <div class="link-navigation-item">{{ $t('category.demandCenter') }}</div>
      </router-link>
      <router-link to="/center/sourcecode">
        <div class="link-navigation-item">{{ $t('category.sourceCodeHall') }}</div>
      </router-link>
      <router-link to="/center/design">
        <div class="link-navigation-item">{{ $t('category.designCenter') }}</div>
      </router-link>
      <router-link to="/center/techservice">
        <div class="link-navigation-item">{{ $t('category.serviceCenter') }}</div>
      </router-link>
      <router-link to="/center/techdocs">
        <div class="link-navigation-item">{{ $t('category.documentCenter') }}</div>
      </router-link>
      <router-link to="/center/service-provider">
        <div class="link-navigation-item">{{ $t('category.serviceProviderCenter') }}</div>
      </router-link>
      <router-link to="/center/parttime">
        <div class="link-navigation-item">{{ $t('category.ITPluralism') }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeMenu',
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
/*@formatter:off*/
.link-navigation {display: flex;font-size: 15px;font-weight: bold;}
.link-navigation a {color: #333;}
.link-navigation .link-navigation-item {display: flex;padding: 14px 20px;position: relative;align-items: center;}
.link-navigation .link-navigation-item:hover {background-color: #F60;color: white;}
.link-navigation-item.first {background-color: #F60;color: #FFF;width: 150px;}
.link-navigation-item img {height: 14px;margin-right: 5px;}
.router-link-active .link-navigation-item:after {content: '';position: absolute;display: block;top: 0;right: 0;bottom: 0;left: 0;border-bottom: 3px #F00 solid;}
/*@formatter:on*/
</style>
